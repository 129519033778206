import { defineStore } from "pinia";
import hash from "object-hash";
import { useExclusion } from "../store/exclusion.ts";
import { useUserStore } from "@/store/user";
import { useOrgStore } from "@/store/org";
import SearchFilter from "~/types/SearchFilterType";
import SearchFilterAttributes, {
  FilterDomains,
} from "~/types/attributes/SearchFilterAttributesType";
import MediaListItem from "~/types/MediaListItemType";
import { useMediaListStore } from "@/store/mediaList";
import { useManageStore } from "~/store/manage";
import { useSearchStore } from "~/store/search";
import { useApiCall } from "~/composables/useApiCall";
import { hasNonEmptyAttribute } from "~/utils/HasNonEmptyAttribute";
import { useEmitNotification } from "~/composables/useEmitNotification";
import MediaListDomainAttributes from "~/types/attributes/MediaListDomainAttributesType";
import RequestOptions from "~/types/RequestOptionsType";

type SearchFilterState = {
  currentSearchFilter: SearchFilterAttributes | null;
  currentSearchFilterBackup: SearchFilterAttributes | null;
  searchFilters: Map<string | undefined, SearchFilterAttributes>;
  excludedPublications: FilterDomains;
  searchFilterHash: string;
  resetSearchFilter: boolean;
};

export const useSearchFiltersStore = defineStore("searchFilters", {
  state: (): SearchFilterState => ({
    currentSearchFilter: null,
    currentSearchFilterBackup: null,
    searchFilters: new Map(),
    excludedPublications: { web: "", print: "", tv: "", radio: "" },
    searchFilterHash: "",
    resetSearchFilter: false,
  }),
  getters: {
    defaultSearchFilter(): SearchFilterAttributes {
      return {
        aggregation_fields: "articles|social|reach|article_sentiment|ave",
        aggregation_scale: "day",
        filter_pagerank_min: 0,
        filter_pagerank_max: 10,
        filter_languages: "",
        web_category: [],
        print_category: [],
        filter_countries: "",
        filter_relevance: 2,
        filter_article_sentiment: "positive|neutral|negative",
        filter_included_domains: this.filterIncludedDomains,
        filter_excluded_domains: this.filterExcludedDomains,
        exclude_financial_news: true,
        exclude_press_releases: false,
        exclude_market_research: false,
        web_media_type: true, // always available at this time.
        print_media_type: this.hasPrintMediaTypeAccess,
        tv_media_type: this.hasBroadcastMediaTypeAccess,
        radio_media_type: this.hasBroadcastMediaTypeAccess,
        show_only_subscribed_markets: false,
      };
    },
    hasPrintMediaTypeAccess(): boolean {
      const orgStore = useOrgStore();
      return orgStore.hasPrintMediaAccess || false;
    },
    hasBroadcastMediaTypeAccess(): boolean {
      // We don't differentiate between TV and Radio access.
      const orgStore = useOrgStore();
      return orgStore.hasBroadcastMediaAccess || false;
    },
    getUserSearchFilters(state): Array<SearchFilterAttributes> {
      return [...state.searchFilters.values()].filter(
        (searchFilter) => searchFilter?.visibility !== "profile",
      );
    },
    getCurrentSearchFilter(state): SearchFilterAttributes {
      return state.currentSearchFilter ?? this.defaultSearchFilter;
    },
    filterIncludedDomains(): FilterDomains {
      return this.computeTmlFilters("getIncludedMediaLists");
    },
    filterExcludedDomains(): FilterDomains {
      const excludedDomains = this.computeTmlFilters("getExcludedMediaLists");
      const exclusionStore = useExclusion();
      exclusionStore.excludedDomains.forEach((obj) => {
        if (obj.type === "excluded_domain") {
          excludedDomains[obj.attributes.media_type] =
            `${excludedDomains[obj.attributes.media_type]}|${obj.attributes.url}`;
        }
      });
      return excludedDomains;
    },
    excludedDomains(state): FilterDomains {
      return state.excludedPublications;
    },
    isCurrentFilterDefault(state): boolean {
      return (
        !state?.currentSearchFilter?.hasOwnProperty("id") ||
        state?.currentSearchFilter?.visibility === "profile"
      );
    },
    hasCurrentSearchFilterChanged(state): boolean {
      const mediaListStore = useMediaListStore();

      return (
        hash(state?.currentSearchFilter) !==
          hash(state?.currentSearchFilterBackup) ||
        hash(mediaListStore?.includedMediaLists) !==
          hash(mediaListStore?.includedMediaListsBackup) ||
        hash(mediaListStore?.excludedMediaLists) !==
          hash(mediaListStore?.excludedMediaListsBackup)
      );
    },
  },
  actions: {
    computeTmlFilters(
      listName: "getIncludedMediaLists" | "getExcludedMediaLists",
    ): FilterDomains {
      // 'seen' keeps track of which domains we've added to the filter to avoid adding duplicates
      const seen = new Set();
      const mediaListStore = useMediaListStore();
      const filters: FilterDomains = { web: "", print: "", tv: "", radio: "" };
      mediaListStore[listName].forEach((mediaList) => {
        const domains =
          mediaListStore.mediaListIdDomainMap.get(mediaList.id) || [];
        domains.forEach((domain: MediaListDomainAttributes) => {
          if (!seen.has(domain)) {
            filters[domain.media_type] =
              filters[domain.media_type] + (domain.name || domain.url) + "|";
            seen.add(domain);
          }
        });
      });
      return filters;
    },
    addSearchFilters(searchFilters: Array<SearchFilter>) {
      searchFilters?.forEach((searchFilter) => {
        const id = String(searchFilter?.id);
        if (!this.searchFilters.has(id)) {
          this.searchFilters.set(id, {
            id,
            ...(searchFilter?.attributes ?? searchFilter),
          });
        }
      });
    },
    setCurrentSearchFilter(
      searchFilter?: SearchFilterAttributes | null,
      performArticleSearch: Boolean = true,
    ) {
      const orgStore = useOrgStore();
      const savedSearchStore = useSearchStore();
      this.currentSearchFilter = JSON.parse(
        JSON.stringify(searchFilter ?? this.defaultSearchFilter),
      );

      // Update the include/exclude TML selections to reflect the newly selected filter
      if (searchFilter) {
        this.searchFilterHash = hash(searchFilter);
        const mediaListStore = useMediaListStore();
        const excludedLists: Array<MediaListItem> = [];
        const includedLists: Array<MediaListItem> = [];
        if (searchFilter.excluded_media_lists) {
          searchFilter.excluded_media_lists.forEach((list) => {
            excludedLists.push({
              id: String(list.id),
              name: list.name,
            });
          });
          mediaListStore.setExcludedMediaLists(excludedLists);
        }
        if (searchFilter.included_media_lists) {
          searchFilter.included_media_lists.forEach((list) => {
            includedLists.push({
              id: String(list.id),
              name: list.name,
            });
          });
          mediaListStore.setIncludedMediaLists(includedLists);
        }

        // * condition commented out for now, enable when rails is fixed to update this field
        // if (searchFilter.filter_excluded_domains) {
        this.getExcludedPublication();
        // }
      }

      if (this.currentSearchFilter?.article_sentiment) {
        this.currentSearchFilter.filter_article_sentiment =
          this.currentSearchFilter.article_sentiment;
      }

      if (this.currentSearchFilter?.pagerank_min !== undefined) {
        this.currentSearchFilter.filter_pagerank_min =
          this.currentSearchFilter.pagerank_min;
      }

      if (this.currentSearchFilter?.relevance !== undefined) {
        this.currentSearchFilter.filter_relevance =
          this.currentSearchFilter.relevance;
      }

      if (this.currentSearchFilter?.languages !== undefined) {
        this.currentSearchFilter.filter_languages =
          this.currentSearchFilter.languages;
      }

      if (this.currentSearchFilter?.countries !== undefined) {
        const acceptedCountries: Array<string> = [];

        const hasCountries = this.currentSearchFilter.countries.trim() !== "";
        if (hasCountries) {
          this.currentSearchFilter.countries
            .split("|")
            .forEach((selectedCountry: string) => {
              const matchingCounty = orgStore.getAllowedCountries.find(
                (country) => country.iso === selectedCountry,
              );
              if (matchingCounty) {
                acceptedCountries.push(selectedCountry);
              } else {
                useEmitNotification({
                  showSuccessMessage: false,
                  successMessage: "",
                  errorMessage: "warnings.search_filter_missing_country",
                  translateMessage: true,
                  translationOptions: {
                    country: selectedCountry,
                  },
                });
              }
            });
        }

        this.currentSearchFilter.filter_countries = acceptedCountries.join("|");
        this.currentSearchFilter.countries = acceptedCountries.join("|");
      }

      if (this.currentSearchFilter) {
        this.currentSearchFilter.web_category =
          this.currentSearchFilter?.web_category ?? [];
        this.currentSearchFilter.print_category =
          this.currentSearchFilter?.print_category ?? [];
        this.currentSearchFilter.filter_included_domains =
          this.filterIncludedDomains;
        this.currentSearchFilter.filter_excluded_domains =
          this.filterExcludedDomains;
        this.currentSearchFilter.aggregation_fields =
          "articles|social|reach|article_sentiment|ave";
        this.currentSearchFilter.aggregation_scale = "day";

        if (this.currentSearchFilter?.id) {
          this.searchFilters.set(
            String(this.currentSearchFilter?.id),
            this.currentSearchFilter,
          );
        }
      }

      if (performArticleSearch) {
        // we dont always need to fire off an article search when we set filters.
        savedSearchStore.articleSearchRequest();
      }

      this.currentSearchFilterBackup = JSON.parse(
        JSON.stringify(this.currentSearchFilter),
      );
    },
    updatePagerankMin(value: number) {
      if (this.currentSearchFilter) {
        this.currentSearchFilter.filter_pagerank_min = value;
      }
    },
    updateRelevance(value: number) {
      // Translate slider values (0-3) into GCH values (0-6)
      if (this.currentSearchFilter) {
        this.currentSearchFilter.filter_relevance = value * 2;
      }
    },
    updateArticleSentiment(value: string) {
      if (this.currentSearchFilter) {
        this.currentSearchFilter.filter_article_sentiment = value;
      }
    },
    resetSearchFilters() {
      const mediaListStore = useMediaListStore();
      const savedSearchStore = useSearchStore();
      this.currentSearchFilter = JSON.parse(
        JSON.stringify(this.currentSearchFilterBackup),
      );
      mediaListStore.resetMediaLists();
      savedSearchStore.articleSearchRequest();
    },
    getExcludedPublication() {
      const manageStore = useManageStore();
      const savedSearchStore = useSearchStore();

      // Filter excluded publications for the selected search
      const filteredExcludedPublicationResults =
        manageStore.excludedPublications.filter(
          (pub) =>
            pub.relationships.search_dashboard.data.id ===
            savedSearchStore.selectedSearch.id,
        );

      // Reduce filtered results to construct the excluded publications object
      const results: FilterDomains = filteredExcludedPublicationResults.reduce(
        (result, exPub) => {
          const mediaType = exPub.attributes.media_type as keyof FilterDomains;
          const url = exPub.attributes.url;

          // Update the result object based on media type
          if (!result[mediaType]) {
            result[mediaType] = url;
          } else {
            result[mediaType] += `|${url}`;
          }
          return result;
        },
        { web: "", print: "", tv: "", radio: "" } as FilterDomains, // Specify initial shape explicitly
      );

      // Assign the constructed excluded publications object to the class property
      this.excludedPublications = results;
    },
    async createSearchFilter(
      filterName: String,
      filterVisibility: String,
      profileID: String | null = null,
    ) {
      if (this.currentSearchFilter) {
        const userStore = useUserStore();
        const orgStore = useOrgStore();
        const mediaListStore = useMediaListStore();

        const includedMediaLists =
          mediaListStore?.includedMediaLists?.map((list) => {
            return {
              id: String(list.id),
              type: "media-lists",
            };
          }) ?? [];
        const excludedMediaLists =
          mediaListStore?.excludedMediaLists?.map((list) => {
            return {
              id: String(list.id),
              type: "media-lists",
            };
          }) ?? [];

        const MediaLists = [...includedMediaLists, ...excludedMediaLists];

        const options: RequestOptions = {
          method: "POST",
          body: {
            data: {
              attributes: {
                countries: this.currentSearchFilter.filter_countries,
                filter_countries:
                  this.currentSearchFilter.filter_countries !== "",
                filter_languages:
                  this.currentSearchFilter.filter_languages !== "",
                languages: this.currentSearchFilter.filter_languages,
                web_category: this.currentSearchFilter.web_category,
                print_category: this.currentSearchFilter.print_category,
                name: filterName,
                pagerank_max: this.currentSearchFilter.filter_pagerank_max,
                pagerank_min: this.currentSearchFilter.filter_pagerank_min,
                print_media_type: this.currentSearchFilter.print_media_type,
                radio_media_type: this.currentSearchFilter.radio_media_type,
                relevance: this.currentSearchFilter.filter_relevance,
                show_only_subscribed_markets:
                  this.currentSearchFilter.show_only_subscribed_markets,
                tv_media_type: this.currentSearchFilter.tv_media_type,
                visibility: filterVisibility,
                web_media_type: this.currentSearchFilter.web_media_type,
                exclude_financial_news:
                  this.currentSearchFilter.exclude_financial_news,
                exclude_market_research:
                  this.currentSearchFilter.exclude_market_research,
                exclude_press_releases:
                  this.currentSearchFilter.exclude_press_releases,
                article_sentiment:
                  this.currentSearchFilter.filter_article_sentiment,
                filter_excluded_domains: hasNonEmptyAttribute(
                  this.filterExcludedDomains,
                ),
                filter_included_domains: hasNonEmptyAttribute(
                  this.filterIncludedDomains,
                ),
              },
              relationships: {
                included_media_lists: { data: includedMediaLists },
                excluded_media_lists: { data: excludedMediaLists },
                media_lists: { data: MediaLists },
                organization: {
                  data: {
                    type: "organization",
                    id: orgStore.getOrg?.id,
                  },
                },
                user: {
                  data: {
                    type: "user",
                    id: userStore.getUser?.id,
                  },
                },
              },
              type: "search-filters",
            },
          },
        };

        if (filterVisibility === "profile" && profileID && options?.body) {
          options.body.data.relationships.profile = {
            data: {
              type: "profile",
              id: profileID,
            },
          };
        }

        const response = await useApiCall("/search_filters", options);

        if (response?.data?._rawValue?.data) {
          this.addSearchFilters([response?.data?._rawValue?.data]);
          const newSearchFilter = [...this.searchFilters.values()].find(
            (searchFilter) =>
              searchFilter.id === response?.data?._rawValue?.data?.id,
          );
          if (newSearchFilter) {
            this.setCurrentSearchFilter(newSearchFilter);
          }
        }
      }
    },
    async updateSearchFilter() {
      if (this.currentSearchFilter?.id) {
        const userStore = useUserStore();
        const orgStore = useOrgStore();
        const mediaListStore = useMediaListStore();

        const includedMediaLists =
          mediaListStore?.includedMediaLists?.map((list) => {
            return {
              id: String(list.id),
              type: "media-lists",
            };
          }) ?? [];
        const excludedMediaLists =
          mediaListStore?.excludedMediaLists?.map((list) => {
            return {
              type: "media-lists",
              id: String(list.id),
            };
          }) ?? [];

        const MediaLists = [...includedMediaLists, ...excludedMediaLists];

        const options: RequestOptions = {
          method: "PATCH",
          body: {
            data: {
              id: this.currentSearchFilter.id,
              attributes: {
                countries: this.currentSearchFilter.filter_countries,
                filter_countries:
                  this.currentSearchFilter.filter_countries !== "",
                filter_languages:
                  this.currentSearchFilter.filter_languages !== "",
                languages: this.currentSearchFilter.filter_languages,
                web_category: this.currentSearchFilter.web_category,
                print_category: this.currentSearchFilter.print_category,
                name: this.currentSearchFilter.name,
                pagerank_max: this.currentSearchFilter.filter_pagerank_max,
                pagerank_min: this.currentSearchFilter.filter_pagerank_min,
                print_media_type: this.currentSearchFilter.print_media_type,
                radio_media_type: this.currentSearchFilter.radio_media_type,
                relevance: this.currentSearchFilter.filter_relevance,
                show_only_subscribed_markets:
                  this.currentSearchFilter.show_only_subscribed_markets,
                tv_media_type: this.currentSearchFilter.tv_media_type,
                visibility: this.currentSearchFilter.visibility,
                web_media_type: this.currentSearchFilter.web_media_type,
                exclude_financial_news:
                  this.currentSearchFilter.exclude_financial_news,
                exclude_market_research:
                  this.currentSearchFilter.exclude_market_research,
                exclude_press_releases:
                  this.currentSearchFilter.exclude_press_releases,
                article_sentiment:
                  this.currentSearchFilter.filter_article_sentiment,
                filter_excluded_domains: hasNonEmptyAttribute(
                  this.filterExcludedDomains,
                ),
                filter_included_domains: hasNonEmptyAttribute(
                  this.filterIncludedDomains,
                ),
              },
              relationships: {
                included_media_lists: { data: includedMediaLists },
                excluded_media_lists: { data: excludedMediaLists },
                media_lists: { data: MediaLists },
                organization: {
                  data: {
                    type: "organizations",
                    id: orgStore.getOrg?.id,
                  },
                },
                user: {
                  data: {
                    type: "users",
                    id: userStore.getUser?.id,
                  },
                },
              },
              type: "search-filters",
            },
          },
        };

        try {
          const searchFilterResponse = await useApiCall(
            `/search_filters/${this.currentSearchFilter.id}`,
            options,
          );

          // Create an object containing id and all attributes
          const { id, attributes } = searchFilterResponse.data._rawValue.data;
          this.$state.searchFilters.set(id, { id, ...attributes });

          this.setCurrentSearchFilter(
            this.$state.searchFilters.get(
              searchFilterResponse.data._rawValue.data.id,
            ),
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    },
    async deleteSearchFilter(id: string) {
      let searchFilterDeleted = false;
      const options: RequestOptions = {
        method: "DELETE",
      };

      // TODO: Add Error Handling
      await useApiCall(`/search_filters/${id}`, options);

      searchFilterDeleted = this.searchFilters.delete(id);

      return searchFilterDeleted;
    },
  },
});
